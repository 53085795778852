import React from 'react'
import { sectionTitleHolder, superTitleHolder, heroTitleHolder, heroSubtitleHolder } from './PageElements.module.scss'
import { motion } from 'framer-motion'




export function SectionTitle({children, inView}) {
  return (
      <h3 className={sectionTitleHolder}>
        {children}
      </h3>
  )
}

export function SuperTitle({children, inView, title}) {
  const split = title.split('')

  const cH = {
    pre: {

    },
    vis: {
      transition: {
        staggerChildren: 0.05
      }
    },
    post: {

    }
  }

  const cVar = {
    pre: {
      opacity:0,
      x:'-1rem'
    },
    vis: { 
      opacity:1,
      x:0,
      transition: {
        type:"tween",
        duration:0.6
      }
    },
    post: {
      x:'1rem',
      opacity:0
    }
  }

  return (
    <motion.h5 variants={cH} initial="pre" animate={inView ? "vis" : "pre"} exit="post" className={superTitleHolder}>
      {
        split.map((c, index)=>{
          return <motion.span key={index} variants={cVar}>{c}</motion.span>
        })
      }
    </motion.h5>
  )
}

export function HeroTitle({children, inView}) {
  return (
    <h1 className={heroTitleHolder}>
      {children}
    </h1>
  )
}


export function HeroSubtitle({children, inView}) {
  return (
    <h3 className={heroSubtitleHolder}>
      {children}
    </h3>
  )
}

