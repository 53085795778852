import React from "react"
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import {MotionConfig} from 'framer-motion'

import { SuperTitle, HeroSubtitle, HeroTitle } from '../components/components/PageElements'
import Seo from "../components/layout/Seo"

import { aboutHolder } from "./About.module.scss"
import { heroHolder } from "../components/components/PageElements.module.scss"




// markup
const CaseStudiesPage = () => {



  return (
    <MotionConfig transition={{duration:0.75, type:"tween"}}>
    <article className={aboutHolder} >
      <Seo title="Case Studies" description="Sign in to view the case studies" />
      <section className={heroHolder}>
        <div className="lrHolder">
          <div className="left">
            <StaticImage
              src="../images/pages/caseStudies/case-studies-hero.jpg"
              alt="Pygmalion Capital - About Pygmalion Capital"
              quality={80}
              width={800}
              className="roundedCorners"
            />
          </div>
          <div className="right">
            <div className="inner">
              <SuperTitle title="Portfolio" inView={true}/>
              <HeroTitle>Case Studies</HeroTitle>
              <HeroSubtitle>You must have an account to view our case studies.</HeroSubtitle>
              <a className="softButton" href="https://members.pygmalioncapital.com/case-studies">Log In</a>
              <Link className="softButton" to="/contact?type=Website membership request#contact-form">Contact us for an account</Link>
            </div>
          </div>
        </div>
      </section>

    </article>
    </MotionConfig>
  )
}

export default CaseStudiesPage
